//客服管理模块接口

import req from "../utils/req"

//获取排班管理列表 dlmsbapi
export function workList_api(params) {
    return req.get("/otaHotel/workRoster/getlist", params)
}

//获取排班模板列表 dlmsbapi
export function scheduling_api(params) {
    return req.get("/otaHotel/workUseTemplate/getlist", params)
}

// 删除一个排班接口(客服管理>排班管理>删除一个排班)akai
export function deleteByIds_apiak(params) {
    return req.get("/otaHotel/workUseTemplate/deleteByIds", params)
}   

// 删除一个排班管理 akai
export function deleteOneByIds_apiak(params) {
    return req.get("/otaHotel/workRoster/deleteByIds", params)
} 


// 查询客服人员列表(客服管理>排班管理>查询客服列表)akai
export function getRosterManagerList_apiak(params) {
    return req.get("/otaHotel/workRoster/getRosterManagerList", params)
}   

// 获取早班中班晚班的 id akai
export function getClassList_apiak(params) {
    return req.get("/otaHotel/workTemplate/getlist", params)
} 

// 获取班次实用模板分页 akai
export function getCLassMaskList_apiak(params) {
    return req.get("/otaHotel/workUseTemplate/getlist", params)
} 

// 删除一个排班模板的接口 akai
export function deleteOneMaskByIds_apiak(params) {
    return req.get("/otaHotel/workTemplate/deleteByIds", params)
} 







