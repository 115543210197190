<template>
  <div class="customerService">
    <!-- 管理分类 -->
    <div class="choose">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        active-text-color="#256DEF"
      >
        <el-menu-item v-if="role_customerService_work" index="1">排班管理</el-menu-item>
        <el-menu-item v-if="role_customerService_performance" index="2">绩效考核</el-menu-item>
        <el-menu-item v-if="role_customerService_scheduling" index="3">排班模板</el-menu-item>
      </el-menu>
    </div>
    <!-- 对应页面 -->
    <WorkManagement v-if="index == '1'"></WorkManagement>
    <Achievements v-if="index == '2'"></Achievements>
    <Scheduling v-if="index == '3'"></Scheduling>
  </div>
</template>

<script>
import WorkManagement from "../../components/dialog/customer/WorkManagement.vue";
import Achievements from "../../components/dialog/customer/Achievements.vue";
import Scheduling from "../../components/dialog/customer/Scheduling.vue";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: {
    WorkManagement,
    Achievements,
    Scheduling,
  },
  data() {
    return {
      role_customerService_work:getRoleKey('role_customerService_work'),
      role_customerService_performance:getRoleKey('role_customerService_performance'),
      role_customerService_scheduling:getRoleKey('role_customerService_scheduling'),
      activeIndex: "1", //管理分类
      index: "1", //管理分类与组件对应展示的方式
    };
  },
  methods: {
    //管理分类
    handleSelect(key, keyPath) {
    //  console.log(key, keyPath);
      this.index = key;
    },
  },
};
</script>

<style lang="less" scoped>
.customerService {
  .choose {
    width: 100%;
    height: 60px;
    background: #fff;
    margin-bottom: 20px;
    .el-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    }
  }
}
</style>