<template>
  <div class="reviseWorkManagement">
    <el-dialog
      title="班次模板"
      :visible.sync="dialogFormVisible"
      width="40%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <div class="btn">
        <el-button class="addBtn" size="small" type="primary" plain @click="add"
          >新增</el-button
        >
      </div>
      <el-table :data="classData" height="400" style="width: 100%;margin:0px 5px">
        <el-table-column fixed prop="name" label="序号" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.ind }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="班次" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.workName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="上班时间" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.workDateStart }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="下班时间" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.workDateEnd }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="时长" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.workHourAndMinute }}小时
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class="operation">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="modifyBtn"
              @click="revise(scope.row)"
              size="mini"
              >修改</el-button
            >
            <el-button
              type="danger"
              class="delBtn"
              size="mini"
              @click="deleteData(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <ModifyClass :getClassMaskListFn='getClassMaskListFn' ref="modifyClass"></ModifyClass>
    <AddMask :getClassMaskListFn='getClassMaskListFn' ref="addMask"></AddMask>
  </div>
</template>

<script>
import ModifyClass from "./modifyClass.vue";
import AddMask from './AddMask.vue';
import {getCLassMaskList_apiak,getClassList_apiak,deleteOneMaskByIds_apiak} from '../../../apis/customer.js'
export default {
  components: {
    ModifyClass,
    AddMask
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        key: [],
        status: "",
        description: "",
      },
      classData: [], // 表格数据
      formLabelWidth: "80px",
    };
  },
  created(){
    this.getClassMaskListFn();  // 获取模板列表
  },
  methods: {
    // 获取模板列表函数
    async getClassMaskListFn(){
      let {code,data} =await getClassList_apiak({
        page:1,
        rows:100
      });
      this.classData=data.records;
      this.classData.forEach((item,index)=>{
        this.$set(item,'ind',index)
      })
      console.log(data.records,'班次模板列表获取');
    },
    // 开启弹窗
    open() {
      this.dialogFormVisible = true;
    },
    // 修改模板数据
    revise(row) {
       this.$refs.modifyClass.open(row);
    },
    // 删除某个排班模板
    deleteData(row) {
      console.log(row, "删除");
       this.$confirm("此操作将永久删除该组数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dels(row); // 调用删除功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除某个排班模板功能函数
    async dels(row){
        let {code,data}=await deleteOneMaskByIds_apiak({
          ids:row.id
        });
      if (code == 0) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getClassMaskListFn();
      }
    },
    //确认保存
    onSubmit() {},
    close() {
      this.dialogFormVisible = false;
    },
    // 新增班次模板
    add() {
       this.$refs.addMask.open();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form {
  width: 100%;
}
/deep/.el-dialog__body {
  box-sizing: border-box;
  padding: 0px 20px 10px 0px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.addBtn {
  width: 42px;
  height: 20px;
  background: #33b93f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.el-form {
  width: 90%;
  margin: auto;
}
/deep/.el-table td.el-table__cell div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47.5px !important;
}

/deep/.modifyBtn,
/deep/.delBtn {
  width: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px !important;
}
.btn {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
/deep/.el-table--scrollable-y ::-webkit-scrollbar {
  display: none;
}
</style>