<template>
  <div class="achievements">
    <!-- 绩效考核 -->
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="日期">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          @change="selectTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input
          size="small"
          v-model="form.user"
          placeholder="请输入要查询的姓名"
          maxlength="6"
        ></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input
          size="small"
          v-model="form.phone"
          placeholder="请输入要查询的电话"
          maxlength="11"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="searchList"
          >查询</el-button
        >
        <el-button v-if="role_customerService_work_export" size="mini" type="success">导出</el-button>
      </el-form-item>
    </el-form>
    <!-- 绩效表格 -->
    <div class="roleTable">
      <div class="head">
        <!-- 按钮选项 -->
        <div class="btn">
          <el-button
          v-if="role_customerService_work_pre"
            size="mini"
            :class="btnCur == 0 ? 'active' : ''"
            @click="btnFn(0)"
            >售前</el-button
          >
          <el-button
            size="mini"
            v-if="role_customerService_work_next"
            :class="btnCur == 1 ? 'active' : ''"
            @click="btnFn(1)"
            >售后</el-button
          >
          <el-button
            size="mini"
            v-if="role_customerService_work_twice"
            :class="btnCur == 2 ? 'active' : ''"
            @click="btnFn(2)"
            >二次确认</el-button
          >
          <el-button
            size="mini"
            v-if="role_customerService_work_complaint"
            :class="btnCur == 3 ? 'active' : ''"
            @click="btnFn(3)"
            >服务申诉</el-button
          >
        </div>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 售前表格 -->
      <el-table v-if="btnCur == '0'" :data="preSaleData" style="width: 100%">
        <el-table-column
          fixed
          prop="userName"
          label="姓名"
          align="center"
          width="100px"
        >
        </el-table-column>
        <el-table-column
          prop="roleKey"
          label="性别"
          align="center"
          width="100px"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.sex == "0"
                  ? "女"
                  : scope.row.sex == "1"
                  ? "男"
                  : scope.row.sex
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="telephone"
          label="电话"
          align="center"
          width="120px"
        >
        </el-table-column>
        <el-table-column
          prop="workLongTime"
          label="上班时长"
          align="center"
          width="130px"
        >
        </el-table-column>
        <el-table-column label="订单量" align="center">
          <template slot-scope="scope">
            <div class="totalOrder">
              <div class="top">
                总订单: {{ scope.row.orderVolumeVo.totalOrderVolume }}
              </div>
              <div class="bottom">
                <div class="item">
                  携程:{{ scope.row.orderVolumeVo.ctripOrderVolume }}
                </div>
                <div class="item">
                  艺龙:{{ scope.row.orderVolumeVo.elongOrderVolume }}
                </div>
                <div class="item">
                  飞猪:{{ scope.row.orderVolumeVo.filPigOrderVolume }}
                </div>
                <div class="item">
                  去哪儿:{{ scope.row.orderVolumeVo.qunarOrderVolume }}
                </div>
                <div class="item">
                  美团:{{ scope.row.orderVolumeVo.meiTuanOrderVolume }}
                </div>
                <div class="item">
                  其他:{{ scope.row.orderVolumeVo.otherOrderVolume }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="平均处理时长" align="center">
          <template slot-scope="scope">
            <div class="totalOrder">
              <div class="top">
                总时长:
                {{ scope.row.averageProcessingTimeVo.totalProcessingTime }}
              </div>
              <div class="bottom">
                <div class="item">
                  携程:{{
                    scope.row.averageProcessingTimeVo.ctripProcessingTime
                  }}
                </div>
                <div class="item">
                  艺龙:{{
                    scope.row.averageProcessingTimeVo.elongProcessingTime
                  }}
                </div>
                <div class="item">
                  飞猪:{{
                    scope.row.averageProcessingTimeVo.filPigProcessingTime
                  }}
                </div>
                <div class="item">
                  去哪儿:{{
                    scope.row.averageProcessingTimeVo.qunarProcessingTime
                  }}
                </div>
                <div class="item">
                  美团:{{
                    scope.row.averageProcessingTimeVo.meiTuanProcessingTime
                  }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="总效率" align="center">
          <template slot-scope="scope">
            <div class="totalOrder efficiency">
              <div class="top">
                总效率:{{ scope.row.processingEfficiencyVo.totalEfficiency }}
              </div>
              <div class="bottom">
                <div class="item">
                  取消率:{{ scope.row.processingEfficiencyVo.cancellationRate }}
                </div>
                <div class="item">
                  确认率:{{ scope.row.processingEfficiencyVo.confirmationRate }}
                </div>
                <div class="item">
                  正常拒单率:{{
                    scope.row.processingEfficiencyVo.normalRejectionRate
                  }}
                </div>
                <div class="item">
                  超时拒单率:{{
                    scope.row.processingEfficiencyVo.overtimeRejectionRate
                  }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 售后表格 -->
      <el-table v-if="btnCur == '1'" :data="afterSaleData" style="width: 100%">
        <el-table-column fixed prop="name" label="姓名" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.userName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="性别" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.sex }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="电话" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.telephone }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="上班时长" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.workLongTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="到店无房" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.eventTypeOne }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="到店查无" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.eventTypeTwo }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="未到店查无" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.eventTypeThree }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="确认后满房" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.eventTypeFour }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="确认后涨价" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.eventTypeFive }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="协商取消" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.eventTypeSix }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 二次确认表格 -->
      <el-table v-if="btnCur == '2'" :data="confirmData" style="width: 100%">
        <el-table-column fixed prop="name" label="姓名" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.userName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="性别" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.sex }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="电话" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.telephone }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="上班时长" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.workLongTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="二次确认订单量" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.secondConfirmation }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 服务申诉表格 -->
      <el-table v-if="btnCur == '3'" :data="serviceData" style="width: 100%">
        <el-table-column fixed prop="name" label="姓名" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.userName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="性别" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.sex }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="电话" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.telephone }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="上班时长" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.workLongTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="处理订单成功量" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.successProOrder }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="未处理订单量" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.notProOrder }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="跟进中订单量" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.followingUpOrder }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleKey" label="无法处理订单量" align="left">
          <template slot-scope="scope">
            <div>
              {{ scope.row.unableProOrder }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getRoleKey} from "@/utils/rolekey"
export default {
  data() {
    return {
      role_customerService_work_export:getRoleKey('role_customerService_work_export'),
      role_customerService_work_pre:getRoleKey('role_customerService_work_pre'),
      role_customerService_work_next:getRoleKey('role_customerService_work_next'),
      role_customerService_work_twice:getRoleKey('role_customerService_work_twice'),
      role_customerService_work_complaint:getRoleKey('role_customerService_work_complaint'),
      // 角色查询新增
      form: {
        user: "", // 姓名
        phone: "", // 手机
        date: "", // 日期
      },
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      preSaleData: [], //售前表格
      afterSaleData: [], //售后表格
      confirmData: [], //二次确认表格
      serviceData: [], //服务申诉表格
      //日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 选项卡按钮
      btnCur: "0",
      //选项按钮
      btn: [
        { name: "售前" },
        { name: "售后" },
        { name: "二次确认" },
        { name: "服务申诉" },
      ],
      // 日间格式字符串
    };
  },
  created() {
    var now = new Date();
    var startDate = new Date(
      Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
    )
      .toISOString()
      .slice(0, 10);
    var endDate = new Date(
      Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
    )
      .toISOString()
      .slice(0, 10);
    this.form.date = [startDate, endDate];
    this.getPreSaleList(this.form.date[0], this.form.date[1], "", ""); // 获取售前列表
    this.getAfterSaleList(this.form.date[0], this.form.date[1], "", ""); // 获取售后列表
    this.getSecondConfirmationList(
      this.form.date[0],
      this.form.date[1],
      "",
      ""
    ); // 获取二次确认列表
    this.getServiceAppealList(this.form.date[0], this.form.date[1], "", ""); // 获取服务申诉列表
  },
  methods: {
    // 选项卡点击事件
    btnFn(index) {
      this.btnCur = index;
      // this.form = {}; // 清空查询输入条件
      // this.form.user = ""; // 清空查询输入条件
      // this.form.phone = ""; // 清空查询输入条件
      if (this.btnCur == "0") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getPreSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售前列表
        } else {
          this.getPreSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售前列表
        }
      } else if (this.btnCur == "1") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getAfterSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售后列表
        } else {
          this.getAfterSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售后列表
        }
      } else if (this.btnCur == "2") {
        if ((this.form.date == null)) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getSecondConfirmationList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取二次确认列表
        } else {
          this.getSecondConfirmationList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取二次确认列表
        }
      } else if (this.btnCur == "3") {
        if ((this.form.date == null)) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getServiceAppealList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取服务申诉列表
        } else {
          this.getServiceAppealList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取服务申诉列表
        }
      }
    },
    //分页
    handleSizeChange(val) {
      this.count = val;
      if (this.btnCur == "0") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getPreSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售前列表
        } else {
          this.getPreSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售前列表
        }
      } else if (this.btnCur == "1") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getAfterSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售后列表
        } else {
          this.getAfterSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售后列表
        }
      } else if (this.btnCur == "2") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getSecondConfirmationList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取二次确认列表
        } else {
          this.getSecondConfirmationList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取二次确认列表
        }
      } else if (this.btnCur == "3") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getServiceAppealList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取服务申诉列表
        } else {
          this.getServiceAppealList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取服务申诉列表
        }
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.btnCur == "0") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getPreSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售前列表
        } else {
          this.getPreSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售前列表
        }
      } else if (this.btnCur == "1") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getAfterSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售后列表
        } else {
          this.getAfterSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售后列表
        }
      } else if (this.btnCur == "2") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getSecondConfirmationList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取二次确认列表
        } else {
          this.getSecondConfirmationList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取二次确认列表
        }
      } else if (this.btnCur == "3") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getServiceAppealList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取服务申诉列表
        } else {
          this.getServiceAppealList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取服务申诉列表
        }
      }
    },
    //获取售前列表
    getPreSaleList(startDate, endDate, telephone, userName) {
      if (this.form.date == null) {
        this.form.date = [];
        this.form.date[0] = "";
        this.form.date[1] = "";
        this.$axios({
          method: "post",
          url: "/otaHotel/performance-appraisal/getPreSaleList",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            page: this.currentPage,
            rows: this.count,
            startDate: startDate,
            endDate: endDate,
            telephone: telephone,
            userName: userName,
          },
        })
          .then((res) => {
          //  console.log(res.data, "列表数据");
            this.total = res.data.data.total;
            this.preSaleData = res.data.data.records;
          })
          .catch((error) => {
            console.log(error, "获取售前列表失败的响应数据");
          });
      } else {
        this.$axios({
          method: "post",
          url: "/otaHotel/performance-appraisal/getPreSaleList",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            page: this.currentPage,
            rows: this.count,
            startDate: startDate,
            endDate: endDate,
            telephone: telephone,
            userName: userName,
          },
        })
          .then((res) => {
          //  console.log(res.data, "列表数据");
            this.total = res.data.data.total;
            this.preSaleData = res.data.data.records;
          })
          .catch((error) => {
            console.log(error, "获取售前列表失败的响应数据");
          });
      }
    },
    // 获取售后列表
    getAfterSaleList(startDate, endDate, telephone, userName) {
      if (this.form.date == null) {
        this.form.date = [];
        this.form.date[0] = "";
        this.form.date[1] = "";
        this.$axios({
          url: "/otaHotel/performance-appraisal/getAfterSaleList",
          method: "post",
          header: {
            "Content-Type": "application/json",
          },
          data: {
            page: this.currentPage,
            rows: this.count,
            startDate: startDate,
            endDate: endDate,
            telephone: telephone,
            userName: userName,
          },
        })
          .then((res) => {
         //   console.log(res.data, "获取到售后列表的响应数据");
            this.total = res.data.data.total;
            this.afterSaleData = res.data.data.records;
          })
          .catch((error) => {
            console.log(error, "获取售后列表失败的响应数据");
          });
      } else {
        this.$axios({
          url: "/otaHotel/performance-appraisal/getAfterSaleList",
          method: "post",
          header: {
            "Content-Type": "application/json",
          },
          data: {
            page: this.currentPage,
            rows: this.count,
            startDate: startDate,
            endDate: endDate,
            telephone: telephone,
            userName: userName,
          },
        })
          .then((res) => {
          //  console.log(res.data, "获取到售后列表的响应数据");
            this.total = res.data.data.total;
            this.afterSaleData = res.data.data.records;
          })
          .catch((error) => {
            console.log(error, "获取售后列表失败的响应数据");
          });
      }
    },
    // 获取二次确认列表
    getSecondConfirmationList(startDate, endDate, telephone, userName) {
      if (this.form.date == null) {
        this.form.date = [];
        this.form.date[0] = "";
        this.form.date[1] = "";
        this.$axios({
          url: "/otaHotel/performance-appraisal/getSecondConfirmationList",
          method: "post",
          header: {
            "Content-Type": "application/json",
          },
          data: {
            page: this.currentPage,
            rows: this.count,
            startDate: startDate,
            endDate: endDate,
            telephone: telephone,
            userName: userName,
          },
        })
          .then((res) => {
         //   console.log(res.data, "获取到二次确认列表的小杨数据");
            this.total = res.data.data.total;
            this.confirmData = res.data.data.records;
          })
          .catch((error) => {
            console.log(error, "获取二次确认列表失败的小杨数据");
          });
      } else {
        this.$axios({
          url: "/otaHotel/performance-appraisal/getSecondConfirmationList",
          method: "post",
          header: {
            "Content-Type": "application/json",
          },
          data: {
            page: this.currentPage,
            rows: this.count,
            startDate: startDate,
            endDate: endDate,
            telephone: telephone,
            userName: userName,
          },
        })
          .then((res) => {
          //  console.log(res.data, "获取到二次确认列表的小杨数据");
            this.total = res.data.data.total;
            this.confirmData = res.data.data.records;
          })
          .catch((error) => {
            console.log(error, "获取二次确认列表失败的小杨数据");
          });
      }
    },
    // 获取服务申诉列表
    getServiceAppealList(startDate, endDate, telephone, userName) {
      if (this.form.date == null) {
        this.form.date = [];
        this.form.date[0] = "";
        this.form.date[1] = "";
        this.$axios({
          url: "/otaHotel/performance-appraisal/getServiceAppealList",
          method: "post",
          header: {
            "Content-Type": "application/json",
          },
          data: {
            page: this.currentPage,
            rows: this.count,
            startDate: startDate,
            endDate: endDate,
            telephone: telephone,
            userName: userName,
          },
        })
          .then((res) => {
         //   console.log(res.data, "获取到服务申诉的小杨数据");
            this.total = res.data.data.total;
            this.serviceData = res.data.data.records;
          })
          .catch((error) => {
            console.log(error, "获取售后列表失败的小杨数据");
          });
      } else {
        this.$axios({
          url: "/otaHotel/performance-appraisal/getServiceAppealList",
          method: "post",
          header: {
            "Content-Type": "application/json",
          },
          data: {
            page: this.currentPage,
            rows: this.count,
            startDate: startDate,
            endDate: endDate,
            telephone: telephone,
            userName: userName,
          },
        })
          .then((res) => {
          //  console.log(res.data, "获取到服务申诉的小杨数据");
            this.total = res.data.data.total;
            this.serviceData = res.data.data.records;
          })
          .catch((error) => {
            console.log(error, "获取售后列表失败的小杨数据");
          });
      }
    },
    // 选择日期的事件处理函数
    selectTime(val) {
      console.log(val, "选择的日期");
    },
    // 点击"查询"按钮的事件处理函数
    searchList() {
      if (this.btnCur == "0") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getPreSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售前列表
        } else {
          this.getPreSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售前列表
        }
      } else if (this.btnCur == "1") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getAfterSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售后列表
        } else {
          this.getAfterSaleList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取售后列表
        }
      } else if (this.btnCur == "2") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getSecondConfirmationList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取二次确认列表
        } else {
          this.getSecondConfirmationList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取二次确认列表
        }
      } else if (this.btnCur == "3") {
        if (this.form.date == null) {
          this.form.date = [];
          this.form.date[0] = "";
          this.form.date[1] = "";
          this.getServiceAppealList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取服务申诉列表
        } else {
          this.getServiceAppealList(
            this.form.date[0],
            this.form.date[1],
            this.form.phone,
            this.form.user
          ); // 获取服务申诉列表
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-table__row {
  background: #f6f8fb !important;
}
/deep/.has-gutter {
  background: #ebf1fa !important;
}
/deep/ .el-table tr {
  background: #ebf1fa !important;
}
/deep/.el-date-editor {
  height: 32px;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
/deep/.el-input__icon {
  margin-top: -5px;
}
/deep/.el-range-input {
  margin-top: 2px;
}
/deep/.el-range-separator {
  margin-top: -8px;
}
/deep/.el-input__inner,
/deep/.el-range-input {
  background: #ebeff4;
}
/deep/ td .cell {
  display: flex;
  justify-content: center;
}
/deep/.has-gutter {
  background: #ebf1fa !important;
}
/deep/.cell {
  display: flex;
  justify-content: center !important;
  align-items: center;
}
.efficiency {
  .top {
    display: flex;
    margin-left: 78px;
  }
  .bottom {
    .item {
      width: 100% !important;
      display: flex;
      margin-left: 40px;
    }
  }
}
.totalOrder {
  padding: 5px 5px;
  box-sizing: border-box;
  .top {
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #256ef1;
    margin-left: 78px;
  }
  .bottom {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    margin-left: 40px;
    align-items: center;
    flex-wrap: wrap;
    .item {
      width: 50%;
      height: 20px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
.achievements {
  /deep/.el-form {
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  .roleTable {
    width: 100%;
    // height: 550px;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .head {
      display: flex;
      justify-content: space-between;
      .btn {
        padding: 10px;
        box-sizing: border-box;
        button {
          border: 1px solid #256def;
          color: #256def;
        }
        .el-button.active {
          background: #256def;
          color: #fff;
        }
      }
      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>