var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reviseWorkManagement"},[_c('el-dialog',{attrs:{"title":"新增","visible":_vm.dialogFormVisible,"width":"40%","top":"10rem","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',{attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"label":"班次","label-width":_vm.formLabelWidth}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入班次名称"},model:{value:(_vm.form.channel),callback:function ($$v) {_vm.$set(_vm.form, "channel", $$v)},expression:"form.channel"}})],1),_c('el-form-item',{attrs:{"label":"上班时间","label-width":_vm.formLabelWidth}},[_c('el-time-select',{attrs:{"placeholder":"上班时间","picker-options":{
            start: '00:00',
            step: '00:15',
            end: '24:00',
          }},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1),_c('el-form-item',{attrs:{"label":"下班时间","label-width":_vm.formLabelWidth}},[_c('el-time-select',{attrs:{"placeholder":"下班时间","picker-options":{
            start: '00:00',
            step: '00:15',
            end: '24:00',
            minTime: _vm.form.startTime,
          }},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1),_c('el-form-item',[_c('div',{staticClass:"btn"},[_c('el-button',{attrs:{"size":"small","type":"primary","plain":""},on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("保存")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }