<template>
  <div class="reviseWorkManagement">
    <el-dialog
      title="修改(班次模板)"
      :visible.sync="dialogFormVisible"
      width="40%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="班次" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.channel"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="上班时间" :label-width="formLabelWidth">
          <el-time-select
            placeholder="上班时间"
            v-model="form.startTime"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="下班时间" :label-width="formLabelWidth">
          <el-time-select
            placeholder="下班时间"
            v-model="form.endTime"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
              minTime: form.startTime,
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getClassMaskListFn: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        channel: "", // 班次
        startTime: "",
        endTime: "",
      },
      formLabelWidth: "80px",
      modifyObj: "",
    };
  },
  methods: {
    open(row) {
      console.log(row, "需要修改的当前数据");
      this.form.startTime = row.workDateStart;
      this.form.endTime = row.workDateEnd;
      this.form.channel = row.workName;
      this.modifyObj = row; // 赋值
      this.dialogFormVisible = true;
    },
    checkType(item) {
      console.log(item, "选择班型");
    },
    // 编辑的处理函数
    modifyFn() {
      this.$axios({
        method: "post",
        url: "/otaHotel/workTemplate/creat",
        headers: {
          "Content-Type": "application/json",
        },
        // dataType: "json",
        data: {
          createTime: this.modifyObj.createTime,
          handlerId: this.modifyObj.handlerId,
          id: this.modifyObj.id,
          isDelete: this.modifyObj.isDelete,
          updateTime: this.modifyObj.updateTime,
          workDateEnd: this.form.endTime + ":00",
          workDateStart: this.form.startTime + ":00",
          workName: this.form.channel,
        },
      })
        .then((res) => {
          console.log(res, "修改成功的响应数据");
          if (res.data.code == 0 && res.data.data == "修改成功") {
            this.$message({
              type: "success",
              message: "恭喜您成功修改班次模板!",
            });
            this.getClassMaskListFn();
          } else {
            this.$message({
              type: "info",
              message: res.data.data,
            });
          }
        })
        .catch((error) => {
          console.log(error, "失败的响应数据");
        });
    },
    //确认保存
    onSubmit() {
      this.modifyFn(); // 调用添加数据
      this.dialogFormVisible = false;
      this.getClassMaskListFn();
      this.form = {};
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  width: 300px;
  height: 40.8px !important;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 80% !important;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>